import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed top-17 z-10 p-2 text-lg rounded danger"
}
const _hoisted_2 = {
  key: 0,
  class: "w-full h-2 border-t-2 border-s-2 border-e-2"
}
const _hoisted_3 = {
  key: 1,
  class: "text-base font-bold text-start mb-2 px-2"
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = ["name", "id", "onUpdate:modelValue", "onBlur", "disabled"]
const _hoisted_6 = {
  key: 1,
  class: "flex gap-2"
}
const _hoisted_7 = ["name", "id", "type", "value", "onUpdate:modelValue", "onBlur", "disabled"]
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "flex gap-2" }
const _hoisted_10 = ["name", "id", "type", "value", "onUpdate:modelValue", "onBlur", "disabled"]
const _hoisted_11 = ["for"]
const _hoisted_12 = {
  key: 2,
  class: "w-full h-2 mb-1 border-b-2 border-s-2 border-e-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_FieldWrapper = _resolveComponent("FieldWrapper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'pt-10': !_ctx.canSave })
  }, [
    (!_ctx.canSave)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('book.cantSave')), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_PageTitle, {
      title: "book.title",
      subtitle: "book.subtitle"
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        (_ctx.getFieldHTMLType(field) !== 'radio' || _ctx.showParentLabel(field, index))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.showParentLabel(field, index))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('book.' + field.parentLabel)), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.getFieldHTMLType(field) !== 'radio' || _ctx.showParentLabel(field, index))
            ? (_openBlock(), _createBlock(_component_FieldWrapper, {
                key: 0,
                label: _ctx.getFieldHTMLType(field) !== 'radio' || (field.label !== 'yes' && field.label !== 'no') ? (_ctx.getFieldHTMLType(field) === 'checkbox' ? 'book.' + field.parentLabel : 'book.' + field.label) : null,
                name: _ctx.getFieldName(field),
                errors: _ctx.validationField(field) ? _ctx.validationField(field).$errors : [],
                class: _normalizeClass(["px-2", { 'w-full': !_ctx.showParentLabel(field, index) }])
              }, {
                default: _withCtx(() => [
                  (field.type === 'PDFTextField')
                    ? _withDirectives((_openBlock(), _createElementBlock("input", {
                        key: 0,
                        name: _ctx.getFieldName(field),
                        id: _ctx.getFieldName(field),
                        type: "text",
                        class: _normalizeClass([{error: _ctx.validationField(field) ? _ctx.validationField(field).$error : null}, "focus:shadow"]),
                        "onUpdate:modelValue": ($event: any) => ((_ctx.filledFields[_ctx.getFieldName(field)]) = $event),
                        onBlur: ($event: any) => (_ctx.validateField(field)),
                        disabled: !_ctx.canSave
                      }, null, 42, _hoisted_5)), [
                        [_vModelText, _ctx.filledFields[_ctx.getFieldName(field)]]
                      ])
                    : (field.type === 'PDFCheckBox')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _withDirectives(_createElementVNode("input", {
                            name: field.inputName,
                            id: _ctx.getFieldName(field),
                            type: _ctx.getFieldHTMLType(field),
                            class: _normalizeClass([{error: _ctx.validationField(field) ? _ctx.validationField(field).$error : null}, "focus:shadow mt-1"]),
                            value: field.label === 'yes' || _ctx.getFieldHTMLType(field) === 'checkbox' ? true : false,
                            "onUpdate:modelValue": ($event: any) => ((_ctx.filledFields[_ctx.getFieldName(field)]) = $event),
                            onBlur: ($event: any) => (_ctx.validateField(field)),
                            disabled: !_ctx.canSave
                          }, null, 42, _hoisted_7), [
                            [_vModelDynamic, _ctx.filledFields[_ctx.getFieldName(field)]]
                          ]),
                          _createElementVNode("label", {
                            for: _ctx.getFieldName(field)
                          }, _toDisplayString(_ctx.$t(field.label)), 9, _hoisted_8)
                        ]))
                      : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["label", "name", "errors", "class"]))
            : _createCommentVNode("", true),
          (_ctx.showParentLabel(field, index))
            ? (_openBlock(), _createBlock(_component_FieldWrapper, {
                key: 1,
                label: _ctx.getFieldHTMLType(_ctx.fields[index + 1]) !== 'radio' || (_ctx.fields[index + 1].label !== 'yes' && _ctx.fields[index + 1].label !== 'no') ? 'book.' + _ctx.fields[index + 1].label : null,
                name: _ctx.getFieldName(_ctx.fields[index + 1]),
                errors: _ctx.validationField(_ctx.fields[index + 1]) ? _ctx.validationField(_ctx.fields[index + 1]).$errors : [],
                class: "px-2"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_9, [
                    _withDirectives(_createElementVNode("input", {
                      name: _ctx.fields[index + 1].inputName,
                      id: _ctx.getFieldName(_ctx.fields[index + 1]),
                      type: _ctx.getFieldHTMLType(_ctx.fields[index + 1]),
                      class: _normalizeClass([{error: _ctx.validationField(_ctx.fields[index + 1]) ? _ctx.validationField(_ctx.fields[index + 1]).$error : null}, "focus:shadow mt-1"]),
                      value: _ctx.fields[index + 1].label === 'yes' || _ctx.getFieldHTMLType(_ctx.fields[index + 1]) === 'checkbox' ? true : false,
                      "onUpdate:modelValue": ($event: any) => ((_ctx.filledFields[_ctx.getFieldName(_ctx.fields[index + 1])]) = $event),
                      onBlur: ($event: any) => (_ctx.validateField(_ctx.fields[index + 1])),
                      disabled: !_ctx.canSave
                    }, null, 42, _hoisted_10), [
                      [_vModelDynamic, _ctx.filledFields[_ctx.getFieldName(_ctx.fields[index + 1])]]
                    ]),
                    _createElementVNode("label", {
                      for: _ctx.getFieldName(_ctx.fields[index + 1])
                    }, _toDisplayString(_ctx.$t(_ctx.fields[index + 1].label)), 9, _hoisted_11)
                  ])
                ]),
                _: 2
              }, 1032, ["label", "name", "errors"]))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.showParentLabel(field, index))
          ? (_openBlock(), _createElementBlock("div", _hoisted_12))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    _createElementVNode("button", {
      class: "primary fixed end-5 bottom-7 z-10",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.generate && _ctx.generate(...args)))
    }, _toDisplayString(_ctx.$t('book.generate')), 1)
  ], 2))
}