import { BookField } from '../interfaces/bookField.model'

export const bookFields: BookField[] = [
  //  {
  //    type: 'PDFTextField',
  //    name: 'Business Name 3',
  //    label: 'Business Name'
  //  },
  {
    type: 'PDFTextField',
    name: 'Owners Name 3',
    label: 'Owners Name'
  },
  //  {
  //    type: 'PDFTextField',
  //    name: 'Date of completion 3',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review date 2',
  //    label: ''
  //  },
  {
    type: 'PDFTextField',
    name: '01cov review name 2',
    label: 'Reviewer name'
  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review date 3',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review name 3',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review date 4',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review name 4',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review date 5',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review name 13',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review date 6',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review name 14',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review date 7',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review name 15',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review date 8',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review name 16',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review date 9',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: '01cov review name 17',
  //    label: ''
  //  },
  //  {
  //    type: 'PDFTextField',
  //    name: 'Date pack first completed main',
  //    label: ''
  //  },
  {
    type: 'PDFCheckBox',
    inputName: 'radio0',
    name: '02 CC Check Box 13',
    parentLabel: 'Are all staff trained to wash their hands before preparing food?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio0',
    name: '02 CC Check Box 12',
    parentLabel: 'Are all staff trained to wash their hands before preparing food?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1',
    name: '02 CC Check Box 11',
    parentLabel: 'Do your staff wear clean work clothes?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1',
    name: '02 CC Check Box5',
    parentLabel: 'Do your staff wear clean work clothes?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio2',
    name: '02 CC Check Box 10',
    parentLabel: 'Do your staff change clothes before starting work?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio2',
    name: '02 CC Check Box 9',
    parentLabel: 'Do your staff change clothes before starting work?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 2',
    label: 'Describe your staff’s work clothes here:'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field  3',
    label: 'What type of aprons do you use?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 1',
    label: 'Which tasks do you use them for?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio3',
    name: '02 CC Check Box 8',
    parentLabel: 'Do staff keep hair tied back?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio3',
    name: '02 CC Check Box 7',
    parentLabel: 'Do staff keep hair tied back?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio4',
    name: '02 CC Check Box 6',
    parentLabel: 'Do staff wear hats or hairnets when preparing food?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio4',
    name: '02 CC Check Box 5',
    parentLabel: 'Do staff wear hats or hairnets when preparing food?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio5',
    name: '02 CC Check Box 4',
    parentLabel: 'Do your staff take off watches and jewellery before preparing food?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio5',
    name: '02 CC Check Box 3',
    parentLabel: 'Do your staff take off watches and jewellery before preparing food?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio6',
    name: '02 CC Check Box 2',
    parentLabel: 'Staff should not smoke, drink, eat or chew gum while handling food_ Staff should also avoid touching their face or nose, or coughing and sneezing over or near food, and wash hands if they do_ Are staff trained not to do these things?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio6',
    name: '02 CC Check Box 1',
    parentLabel: 'Staff should not smoke, drink, eat or chew gum while handling food_ Staff should also avoid touching their face or nose, or coughing and sneezing over or near food, and wash hands if they do_ Are staff trained not to do these things?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio7',
    name: '02 CC Check Box 16',
    parentLabel: 'Do your food handlers understand the importance of being ‘fit for work’ and what they need to report?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio7',
    name: '02 CC Check Box 15',
    parentLabel: 'Do your food handlers understand the importance of being ‘fit for work’ and what they need to report?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio8',
    name: '02 CC Check Box 14',
    parentLabel: 'Do you ensure that food handlers have been free from any symptoms of illness for at least 48 hours before returning to work?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio8',
    name: '02 CC Check Box 1016',
    parentLabel: 'Do you ensure that food handlers have been free from any symptoms of illness for at least 48 hours before returning to work?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 4',
    label: 'Where do staff change and store their outdoor clothes?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 3',
    label: 'Where do you keep clean uniforms/disposable aprons?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 5',
    label: 'How do you clean re-usable cloths?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio9',
    name: '02 CC Check Box 104',
    parentLabel: 'When handling hot items, such as oven trays, do you use a tea towel or chef’s cloth?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 13',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio998',
    name: '02 CC Check Box 105',
    parentLabel: 'Do you use a dish cloth when washing up dishes?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 11',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio10',
    name: '02 CC Check Box 1027',
    parentLabel: 'Do you use disposable cloths or paper towels to wipe surfaces?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 10',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio997',
    name: '02 CC Check Box 1028',
    parentLabel: 'Do you use disposable cloths or paper towels for mopping up spills?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 9',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio11',
    name: '02 CC Check Box 1029',
    parentLabel: 'Do you use disposable cloths or paper towels to wipe your hands?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 8',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio994',
    name: '02 CC Check Box 1030',
    parentLabel: 'Do you use disposable cloths or paper towels to wipe the sides of dishes before serving?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 7',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio12',
    name: '02 CC Check Box 1031',
    parentLabel: 'Do you use disposable cloths or paper towels for drying ingredients?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 6',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 15',
    label: 'Where do staff put dirty re-usable cloths?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 14',
    label: 'Where do you keep new/clean cloths?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 18',
    label: 'When do deliveries come?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 27',
    label: 'How do you make sure raw and ready-to-eat food is stored separately?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio13',
    name: '02 CC Check Box 108',
    parentLabel: 'Are separate containers used for raw and ready-to-eat foods?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio13',
    name: '02 CC Check Box6',
    parentLabel: 'Are separate containers used for raw and ready-to-eat foods?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio14',
    name: '02 CC Check Box 107',
    parentLabel: 'If not, are containers cleaned and disinfected between used?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio14',
    name: '02 CC Check Box 106',
    parentLabel: 'If not, are containers cleaned and disinfected between used?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 28',
    label: 'Where do you defrost foods?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio15',
    name: '02 CC Check Box 1010',
    parentLabel: 'Do you have different areas for raw and ready-to-eat food preparation?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio15',
    name: '02 CC Check Box8',
    parentLabel: 'Do you have different areas for raw and ready-to-eat food preparation?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio16',
    name: '02 CC Check Box 1011',
    parentLabel: 'Do you have same areas used for raw and ready-to-eat food preparation, separated by time and cleaning/disinfection?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio16',
    name: '02 CC Check Box9',
    parentLabel: 'Do you have same areas used for raw and ready-to-eat food preparation, separated by time and cleaning/disinfection?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio17',
    name: '02 CC Check Box 1012',
    parentLabel: 'Do you separate areas, colour-coded utensils for raw and ready-to-eat-food?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio17',
    name: '02 CC Check Box10',
    parentLabel: 'Do you separate areas, colour-coded utensils for raw and ready-to-eat-food?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio18',
    name: '02 CC Check Box 1014',
    parentLabel: 'Do you have a separate sink for washing fruit, salad and vegetables?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio18',
    name: '02 CC Check Box12',
    parentLabel: 'Do you have a separate sink for washing fruit, salad and vegetables?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio19',
    name: '02 CC Check Box 1013',
    parentLabel: 'If not, do you clean and disinfect your sink using a two stage clean between uses and place fruit, salad and vegetables into a suitable container under running water?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio19',
    name: '02 CC Check Box11',
    parentLabel: 'If not, do you clean and disinfect your sink using a two stage clean between uses and place fruit, salad and vegetables into a suitable container under running water?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio20',
    name: '02 CC Check Box 1015',
    parentLabel: 'Do you use different complex equipment for raw and ready-to-eat food preparation (e_g_ mincers, etc)?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio20',
    name: '02 CC Check Box13',
    parentLabel: 'Do you use different complex equipment for raw and ready-to-eat food preparation (e_g_ mincers, etc)?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 19',
    label: 'How do you keep raw meat separate from food already cooking?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio21',
    name: '02 CC Check Box 1018',
    parentLabel: 'Do you have separate probes for raw and ready-to-eat food temperature checks?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio21',
    name: '02 CC Check Box16',
    parentLabel: 'Do you have separate probes for raw and ready-to-eat food temperature checks?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 22',
    label: 'How do you prepare foods for allergic consumers?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 21',
    label: 'How do you store foods once opened?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 20',
    label: 'How do you prevent contamination from allergens in take away orders?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 25',
    label: 'When do you check for pests?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio22',
    name: '02 CC Check Box 1053',
    parentLabel: 'Do you employ a pest control contractor?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio22',
    name: '02 CC Check Box 1052',
    parentLabel: 'Do you employ a pest control contractor?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 24',
    label: 'How do you check deliveries?'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 23',
    label: 'How often do you check external areas?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio2323',
    name: '02 CC Check Box 1032',
    parentLabel: 'Do you regularly inspect your premises for structural or equipment issues, promptly address any problems, and maintain a record of checks, reminders, and repairs in your diary? Additionally, do you schedule regular maintenance and servicing to ensure the condition of the premises and its equipment?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '02 CC Text Field 26',
    label: 'If relevant, provide details below:'
    // <-- Cleaning section -->
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio23',
    name: '03 CL Check Box 106',
    parentLabel: 'Do you use liquid soap?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio23',
    name: '03 CL Check Box 107',
    parentLabel: 'Do you use liquid soap?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '03 CL Text Field 9',
    label: 'If no, what do you use?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio24',
    name: '03 CL Check Box 1034',
    parentLabel: 'Do you use disposable towels?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio24',
    name: '03 CL Check Box 1033',
    parentLabel: 'Do you use disposable towels?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '03 CL Text Field 10',
    label: 'If no, what do you use?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio25',
    name: '03 CL Check Box 1052',
    parentLabel: 'Do you use antibacterial soap which meets standard BS EN 1499?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio25',
    name: '03 CL Check Box 1051',
    parentLabel: 'Do you use antibacterial soap which meets standard BS EN 1499?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '03 CL Text Field 11',
    label: 'If no, what do you use?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio26',
    name: '03 CL Check Box 1054',
    parentLabel: 'Do you clean and disinfect using two stages?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio26',
    name: '03 CL Check Box 1053',
    parentLabel: 'Do you clean and disinfect using two stages?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio27',
    name: '03 CL Check Box 1056',
    parentLabel: 'Have your staff been trained in how to complete the two stage clean?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio27',
    name: '03 CL Check Box 1055',
    parentLabel: 'Have your staff been trained in how to complete the two stage clean?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '03 CL Text Field 5',
    label: 'Where do you keep information to confirm your disinfectants or sanitisers meet BS EN 1276 or BS EN 13697 standards?'
  }, {
    type: 'PDFTextField',
    name: '03 CL Text Field 6',
    label: 'How often do you clean and disinfect items people touch frequently?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio28',
    name: '03 CL Check Box 1058',
    parentLabel: 'Do you have a dishwasher?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio28',
    name: '03 CL Check Box 1057',
    parentLabel: 'Do you have a dishwasher?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio29',
    name: '03 CL Check Box 1060',
    parentLabel: 'If not, do you have separate sinks for washing up raw and ready-to-eat equipment/utensils?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio29',
    name: '03 CL Check Box 1059',
    parentLabel: 'If not, do you have separate sinks for washing up raw and ready-to-eat equipment/utensils?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio30',
    name: '03 CL Check Box 1062',
    parentLabel: 'If you only have one sink, do you clean and disinfect it (including taps/other fittings) using a two stage clean between uses?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio30',
    name: '03 CL Check Box 1061',
    parentLabel: 'If you only have one sink, do you clean and disinfect it (including taps/other fittings) using a two stage clean between uses?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '03 CL Text Field 12',
    label: 'How often do you clean and disinfect fridges?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio31',
    name: '03 CL Check Box 1036',
    parentLabel: 'Have you completed the cleaning schedule from the diary?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio31',
    name: '03 CL Check Box 1035',
    parentLabel: 'Have you completed the cleaning schedule from the diary?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio32',
    name: '03 CL Check Box 1038',
    parentLabel: 'If no, are you using another cleaning schedule?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio32',
    name: '03 CL Check Box 1037',
    parentLabel: 'If no, are you using another cleaning schedule?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio33',
    name: '03 CL Check Box 1040',
    parentLabel: 'Do you make sure you have a good supply of cleaning products?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio33',
    name: '03 CL Check Box 1039',
    parentLabel: 'Do you make sure you have a good supply of cleaning products?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '03 CL Text Field 7',
    label: '‘Clear and clean as you go’ is the recommended way of keeping your kitchen clean as you work_ How do you do this?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio34',
    name: '03 CL Check Box 1042',
    parentLabel: 'Do you remove left-over food from plates before washing?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio34',
    name: '03 CL Check Box 1041',
    parentLabel: 'Do you remove left-over food from plates before washing?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio35',
    name: '03 CL Check Box 1044',
    parentLabel: 'Do you have a bin just for food waste?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio35',
    name: '03 CL Check Box 1043',
    parentLabel: 'Do you have a bin just for food waste?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio36',
    name: '03 CL Check Box 1046',
    parentLabel: 'Do you use strainers in your sinks?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio36',
    name: '03 CL Check Box 1045',
    parentLabel: 'Do you use strainers in your sinks?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio37',
    name: '03 CL Check Box 1048',
    parentLabel: 'Do you have a specific place for food waste?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio37',
    name: '03 CL Check Box 1047',
    parentLabel: 'Do you have a specific place for food waste?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio38',
    name: '03 CL Check Box 1050',
    parentLabel: 'Do you clean and disinfect this area regularly?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio38',
    name: '03 CL Check Box 1049',
    parentLabel: 'Do you clean and disinfect this area regularly?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '03 CL Text Field 8',
    label: 'How often is food waste collected at your business?'
  },
  // starting chilling
  {
    type: 'PDFCheckBox',
    inputName: 'radio39',
    name: '04 CH Check Box 108',
    parentLabel: 'Do you regularly check that foods with a ‘use by’ date, items labeled ‘keep refrigerated’, dishes you’ve cooked but won’t serve immediately, and ready-to-eat foods like salads, cooked meats, sandwiches, and desserts are kept chilled?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 4',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 3',
    label: 'How do you keep track of when food should be used or thrown away?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio899',
    name: '04 CH Check Box 109',
    parentLabel: 'Do you follow the manufacturer’s instructions for using your fridge?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio40',
    name: '04 CH Check Box 1010',
    parentLabel: 'Do you follow the manufacturer’s instructions for using your chilled display unit?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 2',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 1',
    label: 'What do you do to make sure chilled food is displayed safely?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio898',
    name: '04 CH Check Box 26',
    parentLabel: 'Do you check fridge temperature by digital display?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio41',
    name: '04 CH Check Box 28',
    parentLabel: 'Do you check fridge temperature by digital dial thermometer?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio897',
    name: '04 CH Check Box 30',
    parentLabel: 'Do you check fridge temperature by fridge thermometer?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio42',
    name: '04 CH Check Box 31',
    parentLabel: 'Do you check fridge temperature between chilled foods using probe thermometer?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio895',
    name: 'Check Box 30',
    parentLabel: 'Do you check chilled display unit temperature by digital display?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio43',
    name: '04 CH Check Box 32',
    parentLabel: 'Do you check chilled display unit temperature by dial thermometer?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio43612',
    name: 'Check Box 31',
    parentLabel: 'Do you check chilled display unit temperature by fridge thermometer?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio44',
    name: '04 CH Check Box 34',
    parentLabel: 'Do you check chilled display unit temperature between chilled foods using probe thermometer?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH If you do not do this, what do you do?\r 5',
    label: 'If you do not do this, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio869',
    name: '04 CH Check Box 5',
    parentLabel: 'Do you divide food into smaller portions to chill?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio45',
    name: '04 CH Check Box 4',
    parentLabel: 'Do you cut joints of meat in half to chill?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio847',
    name: '04 CH Check Box 3',
    parentLabel: 'Do you cover pans of hot food and transfer them to a cooler area, such as a storage room, or place them in cold water to facilitate chilling?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio46',
    name: '04 CH Check Box 2',
    parentLabel: 'Do you stir food regularly while it is chilling down?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio848',
    name: '04 CH Check Box 1',
    parentLabel: 'Do you spread food out on a tray e_g_ rice while you chilling food?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio47',
    name: '04 CH Check Box 103',
    parentLabel: 'Tick if you have a ‘cool’ setting on your oven, use it to chill down food_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio849',
    name: '04 CH Check Box 102',
    parentLabel: 'Tick if you use a blast chiller to chill down food_',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 5',
    label: 'If you employ alternative methods to cool down hot food, such as placing pasta under cold running water, please provide the details below:'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio48',
    name: '04 CH Check Box 9',
    parentLabel: 'Do you check food is thoroughly defrosted before cooking?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 10',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio444',
    name: '04 CH Check Box 8',
    parentLabel: 'Do you plan ahead to allow adequate time and space for defrosting small amounts of food in the fridge?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 9',
    label: 'How much time do you allow for defrosting?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio443',
    name: '04 CH Check Box 7',
    parentLabel: 'If you are unable to defrost food in the fridge, do you place it in a container and run it under cold water?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 8',
    label: 'Which foods do you defrost in this way?'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 7',
    label: 'How do you defrost raw meat and poultry?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio442',
    name: '04 CH Check Box 6',
    parentLabel: 'Do you use a clean and empty sink for defrosting foods, ensuring it is cleaned and disinfected afterward?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 6',
    label: 'Which foods do you defrost in this way?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio50',
    name: '04 CH Check Box 11',
    parentLabel: 'Do you defrost food in the microwave using the defrost setting?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 13',
    label: 'Which foods do you defrost in this way?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio440',
    name: '04 CH Check Box 10',
    parentLabel: 'Do you sometimes defrost food at room temperature, ensuring you follow manufacturer’s instructions and limit the time it is left out? Ideally, such foods should be defrosted in the fridge_ Do you adhere to this practice?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 12',
    label: 'Which foods do you defrost in this way?'
  }, {
    type: 'PDFTextField',
    name: '04 CH  If you have another method of defrosting, write the details here 1',
    label: 'If you utilize an alternative method for defrosting, please provide the details below:'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 11',
    label: 'Which foods do you defrost in this way?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio51',
    name: '04 CH Check Box 13',
    parentLabel: 'Do you check for ice crystals in food using your hand or a skewer?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio399',
    name: '04 CH Check Box 12',
    parentLabel: 'Do you ensure the joints of birds are flexible as a part of your checks?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 14',
    label: 'If you employ a different method of checking, please provide the details below:'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio52',
    name: '04 CH Check Box 21',
    parentLabel: 'Is frozen food put in the freezer as soon as it is delivered?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio52',
    name: '04 CH Check Box 20',
    parentLabel: 'Is frozen food put in the freezer as soon as it is delivered?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio53',
    name: '04 CH Check Box 19',
    parentLabel: 'Is fresh and cooked food put in the freezer as soon as it has been delivered, prepared, or chilled down?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio53',
    name: '04 CH Check Box 18',
    parentLabel: 'Is fresh and cooked food put in the freezer as soon as it has been delivered, prepared, or chilled down?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio54',
    name: '04 CH Check Box 17',
    parentLabel: 'Is food divided into smaller portions to help it freeze better?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio54',
    name: '04 CH Check Box 16',
    parentLabel: 'Is food divided into smaller portions to help it freeze better?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio55',
    name: '04 CH Check Box 15',
    parentLabel: 'Is frozen food stored in containers or freezer bags?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio55',
    name: '04 CH Check Box 14',
    parentLabel: 'Is frozen food stored in containers or freezer bags?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '04 CH Text Field 15',
    label: 'If you answered ‘No’ to any of the 4 above questions, write down what you do:'
  },
  // cooking page now
  {
    type: 'PDFTextField',
    name: '05 CO Text Field 14',
    label: 'List the dishes containing eggs that you prepare or cook_'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio56',
    name: '05 CO Check Box 107',
    parentLabel: 'Do you cook eggs and food containing eggs thoroughly until they are steaming hot?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 15',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 9',
    label: 'How do you keep rice hot before serving?'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 16',
    label: 'If you chill down rice how do you do this?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1000',
    name: '05 CO Check Box 1',
    parentLabel: 'Do you follow the manufacturer’s instructions when cooking pulses?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 8',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio57',
    name: '05 CO Check Box 2',
    parentLabel: 'If you prepare crabs, crayfish, lobster and scallops, are these prepared by someone with specialist knowledge?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 6',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 7',
    label: 'List the types of shellfish you serve or use as an ingredient_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Your check 1',
    label: 'If you`re using an alternative check for reheating, please describe it below_ Remember, you`d need to validate its safety_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 1',
    label: 'Which specific dishes or food items does this reheating check pertain to?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio9999',
    name: '05 CO Acrylamide check box 1',
    parentLabel: 'When buying raw potatoes ask your supplier for advice on the best variety to use for the type of cooking you are doing_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio58',
    name: '05 CO Acrylamide check box 2',
    parentLabel: 'Store raw, unpeeled potatoes that are going to be fried, baked or roasted in a cool, dark place, above 6⁰C_ Do not store in the fridge_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1001',
    name: '05 CO Acrylamide check box 3',
    parentLabel: 'When buying cooked products from a supplier tell them you will not accept over-baked or burnt products_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio59',
    name: '05 CO Acrylamide check box 4',
    parentLabel: 'Ask your cooking oil supplier for advice on the best oil to use for the type of cooking you are doing_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1002',
    name: '05 CO Acrylamide check box 5',
    parentLabel: 'Cut foods, such as potatoes, to similar sizes_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio60',
    name: '05 CO Acrylamide check box 6',
    parentLabel: 'Where possible, when making home-made chips, or cut potatoes that are going to be deep-fried, follow one of these steps: Soak (for 30–180 mins) in cold water after cutting_ Rinse with clean water and drain_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1003',
    name: '05 CO Acrylamide check box 7',
    parentLabel: 'Or - Soak for a few minutes in warm water_ Rinse with clean water and drain_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio61',
    name: '05 CO Acrylamide check box 8',
    parentLabel: 'Or - blanch potatoes before cooking_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1004',
    name: 'Acrylamide check box 9',
    parentLabel: 'Extend the yeast fermentation time_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio62',
    name: 'Acrylamide check box 10',
    parentLabel: 'Where appropriate, follow the manufacturer’s cooking instructions for food products_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1005',
    name: 'Acrylamide check box 11',
    parentLabel: 'Deep-fry potato products, such as chips and French fries to a golden yellow, or lighter colour_ The oil temperature for cooking should ideally be below 175⁰C_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio63',
    name: 'Acrylamide check box 12',
    parentLabel: 'When deep-frying take care not to over-fill baskets_ Fill the basket only half way_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1007',
    name: 'Acrylamide check box 13',
    parentLabel: 'Keep cooking oil quality at its best by skimming often to remove crumbs and food particles left in the oil_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1008',
    name: 'Acrylamide check box 14',
    parentLabel: 'Filter, change oils and clean cooking equipment as often as needed or as recommended by suppliers_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1009',
    name: 'Acrylamide check box 15',
    parentLabel: 'When baking bread and sweet or savoury bakery products cook to a golden yellow, or lighter colour_ Use the lowest oven temperature possible for the food_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio65',
    name: 'Acrylamide check box 16',
    parentLabel: 'When cooking foods such as toast and toasted sandwiches do not over-toast or burn_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1010',
    name: 'Acrylamide check box 17',
    parentLabel: 'Where possible, set a timer to mark the cooking time_ This could be on the oven or fryer or you can use a separate timer_ Tick if you do it_',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 2',
    label: 'If you serve beef or lamb rare (whole cuts such as steaks and whole joints only), make sure all of the outside surfaces are fully cooked_ List the dishes that follow this guideline_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 3',
    label: 'Check whole birds are cooked to a safe temperature in the thickest part of the leg_ The meat should not be pink or red_ The juices should be clear and not have any pink or red in them_ List the dishes that follow this guideline_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 4',
    label: 'Check rolled meat joints, whole cuts of pork and processed meat products, such as sausages and burgers, are cooked to a safe temperature in the centre with no pink or red_ List the dishes that follow this guideline_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 5',
    label: 'Liver and offal must be cooked to a safe temperature in the centre of the meat_ When preparing dishes such as liver pâté or parfait, the liver should be cooked until there is no pink meat left_ List the dishes that follow this guideline_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 6',
    label: 'Check liquid dishes are heated to a safe temperature in several places in case of cold spots_ List the dishes that follow this guideline_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 7',
    label: 'Check fish is cooked to a safe temperature in the centre and the colour and texture has changed_ Some fish (e_g_ tuna) may be served ‘rare’ as long as they have been frozen correctly beforehand_ List the dishes that follow this guideline_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 8',
    label: 'The largest piece of meat in stews, curries etc_ should be cooked to a safe temperature in the centre with no pink or red_ List the dishes that follow this guideline_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 9',
    label: 'Check combination dishes (e_g_ contains meat and vegetables) are cooked to a safe temperature_ If you are cooking a large dish or batch, check in several places_ List the dishes that follow this guideline_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 10',
    label: 'Check that shellfish such as prawns have changed in colour and texture_ List the dishes that follow this guideline_'
  }, {
    type: 'PDFTextField',
    name: '05 CO Types of dish 11',
    label: 'For dishes containing mussels or clams, do you ensure they are cooked properly by checking if the shell has opened and the meat inside has shrunk? List the dishes that follow this guideline_'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio66',
    name: '05 CO Check Box 6',
    parentLabel: 'Is hot holding part of your process?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio66',
    name: '05 CO Check Box 5',
    parentLabel: 'Is hot holding part of your process?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 17',
    label: 'If `Yes`, which equipment do you utilize for hot holding?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio67',
    name: '05 CO Check Box 4',
    parentLabel: 'Before initiating the hot holding process, do you ensure that food is fully cooked and visibly steaming hot?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio67',
    name: '05 CO Check Box 3',
    parentLabel: 'Before initiating the hot holding process, do you ensure that food is fully cooked and visibly steaming hot?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 18',
    label: 'How do you keep food at the right temperature until it is delivered to a customer?'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 10',
    label: 'Once it is cooked, keep food at 63°C or above until it is served_ How do you do this?'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 12',
    label: 'List the types of ready-to-eat food you use and how you handle them:'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio68',
    name: '05 CO Check Box 8',
    parentLabel: 'Always adhere to the manufacturer`s guidelines for storing and preparing ready-to-eat food_ Do you ensure compliance with these instructions?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio1050',
    name: '05 CO Check Box 7',
    parentLabel: 'When preparing fruits, vegetables, and salads: peel or trim as needed, wash vigorously in clean water starting with the cleanest items, and wash your hands before and after handling them_ After working with vegetables that had dirt or soil, clean and disinfect your boards and surfaces before handling other foods_ Do you follow these practices?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 11',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio69',
    name: '05 CO Check Box 11',
    parentLabel: 'Ensure ready-to-eat foods are stored cold as per the Chilling guidelines_ Do not use them past the use by date_ Label repackaged or prepared items with dates for freshness_ Follow original label storage recommendations_ High-risk foods should be consumed within 3 days (including day of opening or cooking), and prepared rice within a day_ Do you follow these guidelines?',
    label: 'yes'
  }, {
    type: 'PDFTextField',
    name: '05 CO Text Field 13',
    label: 'If not, what do you do?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio777',
    name: '05 CO Check Box 10',
    parentLabel: 'Are staff trained how to clean the meat slicer properly, or supervised?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio777',
    name: '05 CO Check Box 9',
    parentLabel: 'Are staff trained how to clean the meat slicer properly, or supervised?',
    label: 'no'
  },
  // managment below
  {
    type: 'PDFTextField',
    name: '06 MA Opening checks 7',
    label: 'If you have non-standard opening check, please type them below_'
  }, {
    type: 'PDFTextField',
    name: '06 MA Opening checks 6',
    label: 'If you have non-standard opening check, please type them below_'
  }, {
    type: 'PDFTextField',
    name: '06 MA Opening checks 8',
    label: 'If you have non-standard opening check, please type them below_'
  }, {
    type: 'PDFTextField',
    name: '06 MA CLosing checks 6',
    label: 'If you have non-standard closing check, please type them below_'
  }, {
    type: 'PDFTextField',
    name: '06 MA CLosing checks 7',
    label: 'If you have non-standard closing check, please type them below_'
  }, {
    type: 'PDFTextField',
    name: '06 MA CLosing checks 8',
    label: 'If you have non-standard closing check, please type them below_'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 13',
    label: 'How often do you perform a deep cleaning check?'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 7',
    label: 'How often do you check kitchen for damage to equipment or surfaces?'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 15',
    label: 'How often do follow up on any outstanding repairs?'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 17',
    label: 'How often do you remove internal parts and deep clean dishwasher?'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 19',
    label: 'How often do check effectiveness of washing and report any problems of dishwasher?'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 10',
    label: 'How often do you perform a temperature probe check?'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 11',
    label: 'How often do you check all areas inside and outside the building for signs of pest activity?'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 12',
    label: 'How often do you make sure food is covered properly in storage areas?'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 16',
    label: 'How often do you check structure for holes and other places where pests could get in?'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 20',
    label: 'If you have any other checks, please type them below_'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 23',
    label: 'Please type details of them below_'
  }, {
    type: 'PDFTextField',
    name: '06 MA What to do 19b',
    label: 'How often do you perform those check?'
  }, {
    type: 'PDFTextField',
    name: '06 MA Text Field 7',
    label: 'Where do you keep allergen information for the foods you serve?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio71',
    name: '06 MA Check Box 1045',
    parentLabel: 'Do your staff know where to find accurate and up-to-date allergen/ingredient information for the foods you serve?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio71',
    name: '06 MA Check Box 1044',
    parentLabel: 'Do your staff know where to find accurate and up-to-date allergen/ingredient information for the foods you serve?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio72',
    name: '06 MA Check Box 1047',
    parentLabel: 'Have you trained all your staff in how to handle allergen information requests?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio72',
    name: '06 MA Check Box 1046',
    parentLabel: 'Have you trained all your staff in how to handle allergen information requests?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '06 MA Text Field 10',
    label: 'How often do you review staff training in allergen management?'
  }, {
    type: 'PDFTextField',
    name: '06 MA Text Field 11',
    label: 'When do you update your labels?'
  }, {
    type: 'PDFTextField',
    name: '06 MA Text Field 9',
    label: 'Where do you display your signposting so customers know where to find allergen information?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio73',
    name: '06 MA Check Box 1043',
    parentLabel: 'Do you use detailed information in the name or description of dishes on the menu?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio73',
    name: '06 MA Check Box 1042',
    parentLabel: 'Do you use detailed information in the name or description of dishes on the menu?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio74',
    name: '06 MA Check Box 1060',
    parentLabel: 'Do you check if the allergen content of dishes is updated when you change your menu?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio74',
    name: '06 MA Check Box 1051',
    parentLabel: 'Do you check if the allergen content of dishes is updated when you change your menu?',
    label: 'no'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio75',
    name: '06 MA Check Box 1061',
    parentLabel: 'Do you make any ‘free-from’ meals or products?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio75',
    name: '06 MA Check Box 1052',
    parentLabel: 'Do you make any ‘free-from’ meals or products?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '06 MA Text Field 8',
    label: 'How do you make sure that they do not contain the allergen they are free from?'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio76',
    name: '06 MA Check Box 1062',
    parentLabel: 'If you intend to use any precautionary allergen labelling, have you completed a full risk assessment?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio76',
    name: '06 MA Check Box 1053',
    parentLabel: 'If you intend to use any precautionary allergen labelling, have you completed a full risk assessment?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '06 MA Text Field 12',
    label: 'Where do you keep your risk assessment?'
  }, {
    type: 'PDFTextField',
    name: '06 MA Text Field 6',
    label: 'If you receive’ a different product what do you do to ensure your allergen information is up-to-date?'
  }, {
    type: 'PDFTextField',
    name: '06 MA Text Field 5',
    label: 'How do you let customers ordering over the phone or via a website know which allergens are in the foods you serve:'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio77',
    name: '06 MA Check Box 1058',
    parentLabel: 'Are your staff trained in how to take orders over the phone/ website for food allergy customers?',
    label: 'yes'
  }, {
    type: 'PDFCheckBox',
    inputName: 'radio77',
    name: '06 MA Check Box 1057',
    parentLabel: 'Are your staff trained in how to take orders over the phone/ website for food allergy customers?',
    label: 'no'
  }, {
    type: 'PDFTextField',
    name: '06 MA Text Field 4',
    label: 'How do you identify meals prepared for a customer with a food allergy at the point of delivery:'
  }
//  Tutaj jest tak, naprzemiennie jest:
//   1_ data, czyli niech wstawi dzisiejszą
//   2_ podpis, więc niech wstawi po prostu owner name
//  3_ tick box czy metoda dotyczy, niech domyślnie nic nie zaznacza i tyle_
//  spam spam spam dla widoczności jak przewijam spam spam spam dla widoczności jak przewijam spam spam spam dla widoczności jak przewijam spam spam spam dla widoczności jak przewijam spam spam spam dla widoczności jak przewijam
//   {
//      type: 'PDFTextField',
//      name: '06 MA Cross contamination 7',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 37',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio77',
//      name: '06 MA Check Box €203',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Cross contamination 8',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 38',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio78',
//      name: '06 MA Check Box €204',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Cross contamination 9',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 39',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio78',
//      name: '06 MA Check Box €205',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Cross contamination 10',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 40',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio79',
//      name: '06 MA Check Box €206',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Food allergies 6',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 8',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio79',
//      name: '06 MA Check Box €207',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Food allergies 7',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 9',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio80',
//      name: '06 MA Check Box €208',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Food allergies 8',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 10',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio80',
//      name: '06 MA Check Box €209',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Maintenance 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 11',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio81',
//      name: '06 MA Check Box €2010',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Cleaning 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 12',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio81',
//      name: '06 MA Check Box €2011',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Handwashing 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 13',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio82',
//      name: '06 MA Check Box €2012',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Cleaning effectively 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 14',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio82',
//      name: '06 MA Check Box €2013',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Clear and clean as you go 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 15',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio83',
//      name: '06 MA Check Box €2014',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Your cleaning schedule 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 16',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio83',
//      name: '06 MA Check Box €2015',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Chilling 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 17',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio84',
//      name: '06 MA Check Box €2016',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Chilled storage and displaying chilled food 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 18',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio84',
//      name: '06 MA Check Box €2017',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Chilling down hot food 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 19',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio85',
//      name: '06 MA Check Box €2018',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Defrosting 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 20',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio85',
//      name: '06 MA Check Box €2019',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Freezing 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 41',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio86',
//      name: '06 MA Check Box €2020',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Cooking 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 2',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio86',
//      name: '06 MA Check Box € 252',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Cooking safely 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 3',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio87',
//      name: '06 MA Check Box € 253',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Foods that need extra care 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 22',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio87',
//      name: '06 MA Check Box € 254',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA check your menu',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 23',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio88',
//      name: '06 MA Check Box € 255',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 24',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio88',
//      name: '06 MA Check Box € 256',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Hotholding 3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Hotholding 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 25',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio89',
//      name: '06 MA Check Box € 257',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Ready-to-eat food 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 26',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio89',
//      name: '06 MA Check Box € 258',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Management 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 27',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio90',
//      name: '06 MA Check Box € 259',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Opening and closing checks 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 28',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio90',
//      name: '06 MA Check Box € 260',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Extra checks 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 29',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio91',
//      name: '06 MA Check Box € 261',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Prove it 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 30',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio91',
//      name: '06 MA Check Box € 262',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Managing food allergen information 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 31',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio92',
//      name: '06 MA Check Box € 263',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Training and supervision 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 32',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio92',
//      name: '06 MA Check Box € 264',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Customers 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 33',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio93',
//      name: '06 MA Check Box € 265',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Suppliers and contractors 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 34',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio93',
//      name: '06 MA Check Box € 266',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Stock control 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 35',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio94',
//      name: '06 MA Check Box € 267',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Product withdrawal and recall  3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 36',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio94',
//      name: '06 MA Check Box € 268',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Product withdrawal and recall  4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '06 MA Signature 42',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio95',
//      name: '06 MA Check Box € 269',
//      parentLabel: '',
//      label: 'yes'
//   },
//  tu jest tego koniec
// diary
//  tego nie trzeba
//   {
//      type: 'PDFTextField',
//      name: '07 MA Name 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Business 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Address 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Start date 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA End date 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Opening checks 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Opening checks 3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Opening checks 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA CLosing checks 3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA CLosing checks 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA CLosing checks 5',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 282',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 282',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 281',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 281',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 95',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 280',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 280',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 93',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 279',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 279',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 91',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 278',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 278',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 89',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 277',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 277',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 87',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 276',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 276',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 86',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 275',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 275',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 85',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 274',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 274',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1048',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 273',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 273',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1047',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 272',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 272',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 102b 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 77b 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 77b 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1046',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 271',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 271',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1045',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 270',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 270',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1044',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 269',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 269',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1010b 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 85b 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 85b 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1043',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 268',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 268',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 245',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 267',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 267',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 244',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 266',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 266',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 243',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 265',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 265',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 242',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 264',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 264',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 241',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 263',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 263',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 240',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1039',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1039',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 239',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 262',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 262',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 238',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1038',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1038',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 237',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1037',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1037',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 236',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1036',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1036',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 235',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1035',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1035',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 234',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 261',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 261',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 233',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 260',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 260',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 232',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 259',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 259',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 231',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 258',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 258',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Name 13',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Telephone number 13',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Address 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 128',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 140',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 234a',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 234b',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 61',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 61',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Name 11',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Telephone number 11',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Address 12',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 26',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 63',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 63',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 28',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 65',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 65',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 30',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 67',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 67',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 32',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 69',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 69',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 34',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 73',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 73',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 69',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 144',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 130',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 100',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 75',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 75',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 102',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 78',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 78',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 102b 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 77b 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 77b 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 106',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 81',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 81',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 108',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 83',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 83',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1010',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 86',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 86',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1010b 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 85b 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 85b 2',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1038',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 89',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 89',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 128',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 91',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 91',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 130',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 93',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 93',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 132',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 95',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 95',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 134',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 97',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 97',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 136',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 99',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 99',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 138',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 103',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 103',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 142',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 146',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 132',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 105',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 105',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 146',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 107',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 107',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 148',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 109',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 109',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 215',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1029',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1029',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 152',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 150',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 136',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 154',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 152',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 138',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 156',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 154',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 235',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 216',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 234',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 234',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 158',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 257',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 257',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 256',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 256',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 83',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 255',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 255',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 81',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 254',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 254',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 79',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 253',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 253',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 77',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 252',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 252',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 75',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 251',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 251',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 73',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 250',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 250',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 72',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 249',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 249',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1042',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 248',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 248',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 103',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 247',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 247',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 102b 3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 77b 3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 77b 3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1041',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 246',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 246',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1040',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 245',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 245',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1011',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 244',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 244',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1010b 3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 85b 3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 85b 3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1039',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 243',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 243',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 230',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 242',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 242',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 229',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 241',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 241',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 228',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 240',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 240',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 227',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 239',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 239',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 226',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 112',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 113',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 225',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1034',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1034',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 144',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 238',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 238',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 224',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1033',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1033',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 223',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1032',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1032',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 222',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1031',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1031',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 221',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1030',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1030',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 220',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 237',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 144',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 219',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 236',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 140',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 218',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 155',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 237',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 217',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 235',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 236',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Name 12',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Telephone number 12',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Address 13',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Name 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Telephone number 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Address 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 141',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 150',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 307',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 307',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 40',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 123',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 149',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 39',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 122',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 148',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 38',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 121',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 147',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 37',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 71',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 71',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 36',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 70',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 70',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 35',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 120',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 123',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 123',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 148',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 134',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1017',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 119',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 119',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1016',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 79',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 79',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 102b 6',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 77b 6',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 77b 6',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1015',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 118',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 118',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1014',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 117',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 117',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1013',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 87',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 87',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1010b 6',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 85b 6',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 85b 6',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1053',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 116',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 116',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 168',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 115',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 115',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 167',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 111',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 111',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 166',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 110',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 110',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 165',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 101',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 101',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 140',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 100',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 100',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 139',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1014',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1014',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 164',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 147',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 133',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 163',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1013',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1013',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 162',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1012',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1012',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 150',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1011',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1011',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 261',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1045',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1045',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 161',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 159',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 146',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 160',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 158',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 142',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 159',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 157',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 306',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 260',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 306',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 305',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Name 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Telephone number 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Address 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 305',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 304',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 304',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 303',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 119',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 303',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 302',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 116',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 302',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 301',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 115',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 301',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 300',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 111',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 300',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 299',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 110',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 299',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 298',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 98',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 298',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 297',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 96',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 297',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 296',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1052',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 296',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 295',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 104',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 295',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 294',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 102b 5',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 77b 5',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 77b 5',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1051',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 294',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 293',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1050',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 293',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 292',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1012',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 292',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 291',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1010b 5',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 85b 5',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 85b 5',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1049',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 291',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 290',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 259',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 290',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 289',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 258',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 289',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 288',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 257',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 288',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 287',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 256',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 287',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 286',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 255',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 114',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 114',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 254',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1044',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1044',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 149',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 286',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 285',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 253',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1043',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1043',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 252',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1042',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1042',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 251',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1041',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1041',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 250',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1040',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1040',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 249',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 285',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 145',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 248',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 284',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 141',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 247',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 156',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 284',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 246',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 283',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 283',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Name 41',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Telephone number 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Address 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 175',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 175',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 332',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 332',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 46',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 174',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 174',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 45',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 173',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 173',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 44',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 172',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 172',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 43',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 171',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 171',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 42',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 170',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 170',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 41',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 169',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 169',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 185',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 168',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 168',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1024',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 167',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 167',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1023',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 166',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 166',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 102b 8',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 77b 8',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 77b 8',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1022',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 165',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 165',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1021',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 142',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 164',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1020',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 134',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 163',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1010b 8',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 85b 8',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 85b 8',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1058',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 130',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 162',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 184',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 129',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 161',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 183',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 128',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 160',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 182',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 127',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 159',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 181',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 126',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 158',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 180',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 125',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 157',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 179',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1018',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1018',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 178',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 164',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 156',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 177',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1017',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1017',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 176',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1016',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1016',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 175',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1015',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1015',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 277',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1051',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1051',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 174',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 163',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 155',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 173',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 162',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 154',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 172',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 161',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 331',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 276',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 331',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 330',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Name 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Telephone number 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Address 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 330',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 329',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 329',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 328',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 171',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 328',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 327',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 170',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 327',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 326',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 126',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 326',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 325',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 124',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 325',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 324',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 114',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 324',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 323',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 113',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 323',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 322',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 112',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 322',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 321',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1057',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 321',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 320',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1019',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 320',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 319',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 102b 7',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 77b 7',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 77b 7',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1056',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 319',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 318',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1055',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 318',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 317',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1018',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 317',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 316',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1010b 7',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 85b 7',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 85b 7',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 1054',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 316',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 315',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 275',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 315',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 314',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 274',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 314',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 313',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 273',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 313',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 312',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 272',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 312',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 311',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 271',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 124',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 153',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 270',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1050',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1050',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 169',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 311',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 310',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 269',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1049',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1049',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 268',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1048',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1048',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 267',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1047',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1047',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 266',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1046',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 1046',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 265',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 310',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 152',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 264',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 309',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 151',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 263',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 160',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 309',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Cross contamination 262',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 308',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Initials 308',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 28',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio95',
//      name: 'Check Box 15',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio96',
//      name: 'Check Box 14',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio96',
//      name: 'Check Box 13',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio97',
//      name: 'Check Box 12',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio97',
//      name: 'Check Box 11',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio98',
//      name: 'Check Box 10',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio98',
//      name: 'Check Box 9',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 42',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 33',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 45',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 48',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 55',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 58',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 27',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio99',
//      name: 'Check Box 8',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio99',
//      name: 'Check Box 7',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio100',
//      name: 'Check Box 6',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio100',
//      name: 'Check Box 5',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio101',
//      name: 'Check Box 4',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio101',
//      name: 'Check Box 3',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio102',
//      name: 'Check Box 2',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 41',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 32',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 44',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 47',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 54',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 57',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio102',
//      name: 'Check Box 36',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio103',
//      name: 'Check Box 35',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio103',
//      name: 'Check Box 34',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio104',
//      name: 'Check Box 33',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio104',
//      name: 'Check Box 32',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio105',
//      name: 'Check Box 31 SL',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio105',
//      name: 'Check Box 30 SL',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 30',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 52',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 35',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 51',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 50',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 60',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 61',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio106',
//      name: 'Check Box 29',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio106',
//      name: 'Check Box 28',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio107',
//      name: 'Check Box 27',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio107',
//      name: 'Check Box 26',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio108',
//      name: 'Check Box 25',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio108',
//      name: 'Check Box 24',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio109',
//      name: 'Check Box 23',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 29',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 43',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 34',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 46',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 49',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 56',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 59',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio109',
//      name: 'Check Box 43',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio110',
//      name: 'Check Box 42',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio110',
//      name: 'Check Box 41',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio111',
//      name: 'Check Box 40',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio111',
//      name: 'Check Box 39',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio112',
//      name: 'Check Box 38',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio112',
//      name: 'Check Box 37',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 31',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 65',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 36',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 63',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 53',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 62',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Diary Training Initials 112z 64',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 57',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 56',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 55',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 54',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Useful advice on 31',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 53',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 52',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 51',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 50',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Useful advice on 30',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 49',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 48',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 47',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 46',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Useful advice on 29',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 8',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 33',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 32',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 31',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Useful advice on 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 30',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 29',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 28',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 27',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Useful advice on 25',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 26',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 25',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Useful advice on 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 37',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 36',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 35',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 34',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Useful advice on 26',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 41',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 40',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 39',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Contact name 38',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Useful advice on 27',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 19',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 19',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 19',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 19',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 19',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 19',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 19',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 19',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 20',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 20',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 20',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 20',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 20',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 20',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 20',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 20',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 21',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 21',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 21',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 21',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 21',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 21',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 21',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 21',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 22',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 22',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 22',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 22',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 22',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 22',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 22',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 22',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Item 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA After use 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Every shift 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Daily 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Weekly 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Other 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Precautions 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Method of cleaning 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 14',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 3',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 15',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1112',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 16',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1143',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 17',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1154',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 18',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1165',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 19',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 19',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1176',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 20',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 20',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1187',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 21',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 21',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1198',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 22',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 22',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1209',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 23',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1220',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 24',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1222',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Safe method 25',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA How did you prove it? 25',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Date 1233',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 36',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio113',
//      name: 'Check Box 57',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio113',
//      name: 'Check Box 56',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 46',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 45',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 39',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio114',
//      name: 'Check Box 55',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio114',
//      name: 'Check Box 54',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 59',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 55',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 40',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio115',
//      name: 'Check Box 53',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio115',
//      name: 'Check Box 52',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 54',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 53',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 42',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 63',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 62',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 37',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio116',
//      name: 'Check Box 51',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio116',
//      name: 'Check Box 50',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 52',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 51',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 41',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio117',
//      name: 'Check Box 49',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio117',
//      name: 'Check Box 48',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 61',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 60',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 38',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio118',
//      name: 'Check Box 47',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio118',
//      name: 'Check Box 46',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 50',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 47',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 35',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio119',
//      name: 'Check Box 45',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio119',
//      name: 'Check Box 44',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 27',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 44',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Text Field 4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 50',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio120',
//      name: 'Check Box 71',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio120',
//      name: 'Check Box 70',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 73',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 72',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 49',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio121',
//      name: 'Check Box 69',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio121',
//      name: 'Check Box 68',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 71',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 70',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 48',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio122',
//      name: 'Check Box 67',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio122',
//      name: 'Check Box 66',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 69',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 68',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 47',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 67',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 66',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 46',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio123',
//      name: 'Check Box 65',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio123',
//      name: 'Check Box 64',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 58',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 57',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 45',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio124',
//      name: 'Check Box 63',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio124',
//      name: 'Check Box 62',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 65',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 64',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 44',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio125',
//      name: 'Check Box 61',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio125',
//      name: 'Check Box 60',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 56',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 49',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Text Field 5',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 43',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio126',
//      name: 'Check Box 59',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio126',
//      name: 'Check Box 58',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 28',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 48',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 58',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio127',
//      name: 'Check Box 85',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio127',
//      name: 'Check Box 84',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 88',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 87',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 57',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio128',
//      name: 'Check Box 83',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio128',
//      name: 'Check Box 82',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 86',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 85',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 56',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio129',
//      name: 'Check Box 81',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio129',
//      name: 'Check Box 80',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 84',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 83',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 55',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 82',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 81',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 54',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio130',
//      name: 'Check Box 79',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio130',
//      name: 'Check Box 78',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 80',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 79',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 53',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio131',
//      name: 'Check Box 77',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio131',
//      name: 'Check Box 76',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 78',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 77',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 52',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio132',
//      name: 'Check Box 75',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio132',
//      name: 'Check Box 74',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 76',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 75',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Text Field 6',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 51',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio133',
//      name: 'Check Box 73',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio133',
//      name: 'Check Box 72',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 29',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 74',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 58 DI4',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio134',
//      name: 'Check Box 85 DI4',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio134',
//      name: 'Check Box 84 DI4',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 88 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 87 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 57 DI4',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio135',
//      name: 'Check Box 83 DI4',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio135',
//      name: 'Check Box 82 DI4',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 86 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 85 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 56 DI4',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio136',
//      name: 'Check Box 81 DI4',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio136',
//      name: 'Check Box 80 DI4',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 84 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 83 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 55 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 82 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 81 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 54 DI4',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio137',
//      name: 'Check Box 79 DI4',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio137',
//      name: 'Check Box 78 DI4',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 80 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 79 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 53 DI4',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio138',
//      name: 'Check Box 77 DI4',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio138',
//      name: 'Check Box 76 DI4',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 78 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 77 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 52 DI4',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio139',
//      name: 'Check Box 75 DI4',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio139',
//      name: 'Check Box 74 DI4',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 76 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 75 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Text Field 6 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Any problems or changes – what did you do? 51 DI4',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio140',
//      name: 'Check Box 73 DI4',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio140',
//      name: 'Check Box 72 DI4',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 29 DI4',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: 'Name 74 DI4',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio141',
//      name: '07 MA Check Box 10156',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio141',
//      name: '07 MA Check Box 10155',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio142',
//      name: '07 MA Check Box 10154',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio142',
//      name: '07 MA Check Box 10153',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio143',
//      name: '07 MA Check Box 101040',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio143',
//      name: '07 MA Check Box 101039',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio144',
//      name: '07 MA Check Box 101038',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio144',
//      name: '07 MA Check Box 101037',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio145',
//      name: '07 MA Check Box 101036',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio145',
//      name: '07 MA Check Box 101035',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio146',
//      name: '07 MA Check Box 101034',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio146',
//      name: '07 MA Check Box 101033',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio147',
//      name: '07 MA Check Box 101032',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio147',
//      name: '07 MA heck Box 101031',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio148',
//      name: '07 MA Check Box 10152',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio148',
//      name: '07 MA Check Box 10151',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio149',
//      name: '07 MA Check Box 10150',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio149',
//      name: '07 MA Check Box 10149',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio150',
//      name: '07 MA Check Box 10148',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio150',
//      name: '07 MA Check Box 10147',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio151',
//      name: '07 MA Check Box 10146',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio151',
//      name: '07 MA Check Box 10145',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio152',
//      name: '07 MA Check Box 10144',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio152',
//      name: '07 MA Check Box 10143',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Name 55',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Name 56',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio153',
//      name: '07 MA Check Box 10106',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio153',
//      name: '07 MA Check Box 10107',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFTextField',
//      name: '4 weekly review additional details',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Name 53',
//      label: ''
//   }, {
//      type: 'PDFTextField',
//      name: '07 MA Signed52',
//      label: ''
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio154',
//      name: 'Check Box 22ff',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio154',
//      name: 'Check Box 21g',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio155',
//      name: 'Check Box 20g',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio155',
//      name: 'Check Box 19gg',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio156',
//      name: 'Check Box 18ss',
//      parentLabel: '',
//      label: 'yes'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio156',
//      name: 'Check Box 17dr',
//      parentLabel: '',
//      label: 'no'
//   }, {
//      type: 'PDFCheckBox',
//      inputName: 'radio157',
//      name: 'Check Box 16e',
//      parentLabel: '',
//      label: 'yes'
//    },
//
]
