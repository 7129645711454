
import { defineComponent, reactive } from 'vue'
import useVuelidate from '@vuelidate/core'
import { PDFCheckBox, PDFDocument, PDFField, PDFTextField } from 'pdf-lib'
import { required } from '@/customValidators'
import PageTitle from '@/shared/components/PageTitle.vue'
import { bookFields } from '@/shared/consts/bookFields'
import { BookField } from '@/shared/interfaces/bookField.model'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import { formatDate } from '@/shared/utils/formatDate'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'Book',
  components: {
    PageTitle,
    FieldWrapper
  },
  setup: () => {
    const store = useStore()
    store.commit('setVuelidateExternalResults', {})
    return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
  },
  computed: {
    fields (): BookField[] {
      return bookFields
    },
    canSave (): boolean {
      const activeBranch = this.$store.state.activeBranch
      const now = new Date()
      const blockedFrom = new Date(activeBranch.blocked_from)
      const blockedTo = new Date(activeBranch.blocked_to)
      const isBlocked = now.getTime() >= blockedFrom.getTime() && now.getTime() < blockedTo.getTime()
      return activeBranch && activeBranch.hasCard && !isBlocked
    }
  },
  data: function () {
    return this.getData('data')
  },
  validations: function () {
    return this.getData()
  },
  methods: {
    getData (mode: 'data' | 'validation' = 'validation') {
      const filledFields = {} as any
      bookFields.forEach(field => {
        if (mode === 'data') {
          const storedBookField = this.$store.state.bookFields.find((storedField: BookField) => storedField.name === field.name)
          if (storedBookField && storedBookField.type === 'PDFCheckBox') storedBookField.value = storedBookField.value === 'true'
          filledFields[this.getFieldName(field)] = storedBookField ? storedBookField.value : null
        } else if (this.getFieldHTMLType(field) === 'radio') filledFields[this.getFieldName(field)] = { required }
      })
      return {
        filledFields
      }
    },
    fillData () {
      this.$store.state.bookFields.forEach((field: BookField) => {
        if (field.type === 'PDFCheckBox') field.value = field.value === 'true'
        this.filledFields[this.getFieldName(field)] = field.value
      })
    },
    showParentLabel (field: BookField, index: number) {
      return field.type === 'PDFCheckBox' && (index < bookFields.length - 1 && bookFields[index + 1].inputName === field.inputName)
    },
    getFieldName (field: BookField) {
      return field.type === 'PDFCheckBox' && field.inputName ? field.inputName : field.name.split(/\s/).join('-')
    },
    getFieldHTMLType (field: BookField) {
      const isMore = bookFields.find((searchedField : BookField) => {
        return field.name !== searchedField.name && field.inputName && searchedField.inputName === field.inputName
      })
      return field.type === 'PDFTextField' ? 'text' : isMore ? 'radio' : 'checkbox'
    },
    validationField (field: BookField) {
      return this.v$.filledFields[this.v$.filledFields[this.getFieldName(field)]]
    },
    validateField (field: BookField) {
      const validation = this.validationField(field)
      let valid = true
      if (validation) {
        validation.$touch()
        valid = !validation.$error
      }
      const storedBookField = this.$store.state.bookFields.find((storedField: BookField) => storedField.name === field.name)
      const value = this.filledFields[this.getFieldName(field)]
      if (valid && value && (!storedBookField || value !== storedBookField.value)) {
        field.value = value
        this.$store.dispatch('updateBookField', field)
      }
    },
    async generate () {
      this.$store.commit('setAppLoadingText', 'book.generationProcess')
      const pdfFile = require('@/assets/documents/sfbb-caterers-pack-fixed_0.pdf')
      const formPdfBytes = await fetch(pdfFile).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(formPdfBytes)
      const form = pdfDoc.getForm()
      const pdfFields = form.getFields()
      let pdfFieldsCopy = [...pdfFields]
      bookFields.forEach((bookField: BookField) => {
        const value = this.filledFields[this.getFieldName(bookField)]
        const pdfField = pdfFields.find((field: PDFField) => field.getName() === bookField.name)
        const isCheckbox = this.getFieldHTMLType(bookField) === 'checkbox'
        if ((value === true && (bookField.label === 'yes' || isCheckbox)) || (value === false && bookField.label === 'no')) {
          (pdfField as PDFCheckBox).check()
        } else if ((value === false && (bookField.label === 'yes' || isCheckbox)) || (value === true && bookField.label === 'no')) {
          (pdfField as PDFCheckBox).uncheck()
        } else if (value && value !== true) {
          const field = pdfField as PDFTextField
          field.setText(value)
        }
        pdfFieldsCopy = pdfFieldsCopy.filter((searchedField: PDFField) => pdfField && searchedField.getName() !== pdfField.getName())
      })
      let isLastRequiredPartBeginnig = false
      let isLastRequiredPartEnded = false
      let isLastRequiredPartEndedField = pdfFields[pdfFields.length - 1]
      pdfFields.forEach((field: PDFField) => {
        if (field.getName().indexOf('06 MA Signature') >= 0) isLastRequiredPartEndedField = field
      })
      const isLastRequiredPartEndedName = isLastRequiredPartEndedField.getName()
      const valuesToStore: any[] = []
      pdfFieldsCopy.forEach((pdfField: PDFField) => {
        const name = pdfField.getName()
        const type = pdfField.constructor.name
        const storedBookField = this.$store.state.bookFields.find((storedField: BookField) => storedField.name === name)
        const value = storedBookField ? storedBookField.value : null
        if (name.indexOf('06 MA Cross contamination') >= 0) isLastRequiredPartBeginnig = true
        else if (name === isLastRequiredPartEndedName) isLastRequiredPartEnded = true
        if (name === 'Business Name 3') (pdfField as PDFTextField).setText(this.$store.state.loggedUser.companyName)
        else if (name === 'Date of completion 3') (pdfField as PDFTextField).setText(value || formatDate((new Date()).toString(), 'DD/MM/YYYY'))
        else if (name === '01cov review date 2') (pdfField as PDFTextField).setText(value || formatDate((new Date()).toString(), 'DD/MM/YYYY'))
        else if (name === 'Date pack first completed main') (pdfField as PDFTextField).setText(value || formatDate((new Date()).toString(), 'DD/MM/YYYY'))
        else if (name.indexOf('06 MA Signature') >= 0) {
          const ownerPdfField = pdfFields.find((searchedfield: PDFField) => searchedfield.getName() === 'Owners Name 3') as PDFTextField
          const field = pdfField as PDFTextField
          field.setText(value || ownerPdfField.getText())
        } else if (isLastRequiredPartBeginnig && !isLastRequiredPartEnded && type === 'PDFTextField') {
          (pdfField as PDFTextField).setText(value || formatDate((new Date()).toString(), 'DD/MM/YYYY'))
        }
        if (!storedBookField) {
          const valueToSave = type === 'PDFTextField' ? (pdfField as PDFTextField).getText() : type === 'PDFCheckBox' ? (pdfField as PDFCheckBox).isChecked() : null
          if (valueToSave) {
            valuesToStore.push({
              name,
              type,
              value: valueToSave
            })
          }
        }
      })
      this.$store.dispatch('updateBook', valuesToStore)
      const pdfBytes = await pdfDoc.save()
      const blob = new Blob([pdfBytes], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      const fileName = 'sfbb-caterers-pack-fixed_0'
      link.download = fileName
      link.click()
      this.$store.commit('setAppLoadingText', null)
    }
  },
  beforeMount () {
    this.$store.dispatch('getBranches').then(() => this.$store.dispatch('getBook').then(() => this.fillData()))
  }
})
