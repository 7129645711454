
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: String
  }
})
