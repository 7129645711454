import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "title text-start mb-10" }
const _hoisted_2 = { class: "text-black font-bold text-3xl" }
const _hoisted_3 = {
  key: 0,
  class: "text-lg text-textHeaderColor mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.title)), 1),
    (_ctx.subtitle)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.subtitle)), 1))
      : _createCommentVNode("", true)
  ]))
}